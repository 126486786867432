export default {
  VIEWS: {
    ADMIN: {
      CLIENT: {
        DETAILS: {
          COMPONENTS: {
            personalParticularsSection: [
              'name',
              'traditionalName',
              'identityDocumentType',
              'identityDocument',
              'salutation',
              'dateOfBirth',
              'age',
              'nationality',
              'placeOfBirth',
              'gender',
              'race',
              'religion',
              'ethnicity',
              'maritalStatus',
              'householdPattern',
              'languages',
              'riskLevel',
              'workingAuthorityStartDate',
              'workingAuthorityEndDate',
              'centre',
            ],
            socialSupportSection: ['nextOfKin', 'maritalHistory'],
          },
        },
      },
    },
    CASE: {
      SESSION_DETAILS: {
        fields: [
          'title',
          'type',
          'sessionDateTime',
          'sessionStatus',
          'sessionSubtypes',
          'casesInvolved',
          'attendees',
          'vettedAt',
          'vettedBy',
        ],
      },
      SESSION_INFO: {
        submitButtons: ['edit', 'markDefaulted', 'markAttended', 'undoDefaulted', 'undoAttended', 'completeSession'],
      },
      SHOW: {
        pages: ['generalInformation', 'sessions', 'caseAttachments', 'icp', 'temporalPermissions'],
      },
      DETAILS_ASSESSMENT_INTERVENTION_SUMMARY: {
        COMPONENTS: {},
        ENTRIES: [],
      },
    },
    APP_LAYOUT: {
      APP_BAR: {
        COMPONENTS: {
          main: ['publicActivitiesNotification', 'accountButton'],
          dropdown: ['accountPicName', 'divider', 'masquerade', 'normalChangePassword', 'signOut'],
        },
      },
      DRAWER: {
        COMPONENTS: {
          main: ['createNewIntake', 'cases', 'reportsExpand', 'reports', 'adminExpand', 'admin', 'auditLogs'],
          reports: ['staffOverviewReport', 'caseSessionTypeReport'],
          admin: ['clients', 'staffs', 'organisations', 'centres', 'caseTypes'],
        },
      },
    },
    INTAKE: {
      DETAILS_OVERVIEW: {
        COMPONENTS: {
          intakeOverview: [
            'centre',
            'intakeRef',
            'atRisk',
            'intakeDate',
            'closedDate',
            'intakeStatus',
            'assignedCounsellor',
            'intakeOfficer',
            'counsellingFee',
            'referralMode',
            'caseType',
            'divider',
            'referralSources',
            'intakeClients',
            'intakeRemarks',
          ],
          manageIntake: ['assignCaseworker', 'manageIntakeForm', 'intakeDecisions', 'goToCase'],
          intakeHistory: ['intakeOutcomeActivities', 'intakeWorkerHistory', 'contactOrganisation'],
        },
        ENTRIES: ['intakeOverview', 'manageIntake', 'intakeHistory'],
      },
    },
  },
};

export default {
  VIEWS: {
    ADMIN: {
      CLIENT: {
        DETAILS: {
          COMPONENTS: {
            personalParticularsSection: [
              'name',
              'traditionalName',
              'identityDocumentType',
              'identityDocument',
              'salutation',
              'dateOfBirth',
              'nationality',
              'placeOfBirth',
              'gender',
              'race',
              'religion',
              'ethnicity',
              'maritalStatus',
              'householdPattern',
              'languages',
              'riskLevel',
              'centre',
            ],
            contactInformationSection: ['contactDetails', 'addresses', 'emergencyContacts'],
            backgroundInformationSection: [
              'organisation',
              'occupation',
              'occupationStatus',
              'highestEducationLevel',
              'individualIncome',
              'familyIncome',
              'remarksDivider',
              'remarks',
              'specialRequests',
            ],
            socialSupportSection: ['nextOfKin', 'householdMembers', 'maritalHistory'],
            counsellingHistorySection: ['counsellingHistory'],
            clientHistorySection: ['clientIntakeHistory', 'clientCaseHistory', 'clientEventHistory'],
          },
          ENTRIES: [
            'personalParticularsSection',
            'contactInformationSection',
            'backgroundInformationSection',
            'socialSupportSection',
            'counsellingHistorySection',
            'clientHistorySection',
            'buttonGroup',
          ],
        },
      },
      STAFF: {
        COMPONENTS: {},
        ENTRIES: ['personalParticulars', 'contactDetails', 'backgroundInfo', 'staffDetails', 'login'],
      },
    },
    CASE: {
      DETAILS: {
        COMPONENTS: {
          caseRemarksSection: [],
        },
        ENTRIES: [
          'caseOverview',
          'caseIntSum',
          'caseReferralSources',
          'caseClientWorkersHistory',
          'caseRelatedCases',
          'caseLastModifiedStamp',
        ],
      },
      DETAILS_ASSESSMENT_INTERVENTION_SUMMARY: {
        COMPONENTS: {},
        ENTRIES: ['assessmentIntervention'],
      },
      DETAILS_ASSESSMENT_INTERVENTION_SUMMARY_FIELDS: {
        ENTRIES: [
          'keyAssessedProblem',
          'otherAssessedProblems',
          'detailsPresentingProblem',
          'assessmentConceptualisation',
          'intervention',
          'riskAssessment',
          'interventionOutcome',
          'counsellingGoals',
          'totalF2fDuration',
          'totalNonF2fDuration',
        ],
      },
      DETAILS_CLIENTS_WORKER_HISTORY: {
        COMPONENTS: {
          clientsHistory: ['caseClients', 'caseWorkers', 'caseHistory', 'clientsHistoryField'],
        },
        ENTRIES: ['clientsHistory'],
      },
      DETAILS_OTHER_CASEWORKER_REMARKS: {
        COMPONENTS: {},
        ENTRIES: [],
      },
      DETAILS_OVERVIEW: {
        COMPONENTS: {
          caseOverview: [
            'caseStatus',
            'centre',
            'caseNumber',
            'caseRefNumber',
            'caseType',
            'intake',
            'intakeDate',
            'openedDate',
            'closedDate',
            'counsellingFee',
            'caseRemarks',
          ],
        },
        ENTRIES: ['caseOverview'],
      },
      DETAILS_RELATED_CASES: {
        COMPONENTS: {},
        ENTRIES: ['relatedCases'],
      },
      INTAKE_REFERRAL_SOURCES: {
        COMPONENTS: {},
        ENTRIES: ['sourceOfCase'],
      },
      SESSION_DETAILS: {
        fields: ['type', 'sessionDateTime', 'sessionStatus', 'location', 'casesInvolved', 'caseWorkers', 'clients'],
      },
      SESSION_INFO: {
        submitButtons: [
          'edit',
          'markDefaulted',
          'markAttended',
          'undoDefaulted',
          'undoAttended',
          'completeSession',
          'undoCompleted',
        ],
        // This is temporary fix for the issue of "next documentation" button not showing #59.
        // showNextDocumentation should be moved to the backend when refactoring the backend to golang.
        // This is for CCCC, as only it has 'nextDocumentation' in constants.VIEWS.CASE.SESSION_INFO.submitButtons
        showNextDocumentation: [
          'Counselling Session',
          'Counselling Session (Online)',
          'Counselling Session (Phone)',
          'Visitation',
          'Crisis Management',
        ],
      },
      SHOW: {
        pages: [
          'generalInformation',
          'sessions',
          'caseAttachments',
          'temporalPermissions',
        ]
      },
      SUSPENSION_INFO: {
        SUSPENSION: {
          COMPONENTS: {},
          ENTRIES: [],
        },
        UNSUSPENSION: {
          COMPONENTS: {},
          ENTRIES: [],
        },
      },
    },
    INTAKE: {
      DETAILS_OVERVIEW: {
        COMPONENTS: {
          intakeOverview: [
            'centre',
            'intakeRef',
            'atRisk',
            'intakeDate',
            'closedDate',
            'intakeStatus',
            'assignedCounsellor',
            'assistantCounsellor',
            'intakeOfficer',
            'counsellingFee',
            'referralMode',
            'caseType',
            'divider',
            'referralSources',
            'intakeClients',
            'presentingProblems',
            'assessmentByIntakeOfficer',
            'divider',
            'preferredContactTime',
            'specialRequests',
            'divider',
            'actionTaken',
            'noAppointment',
            'divider',
            'intakeRemarks',
          ],
          manageIntake: ['assignCaseworker', 'manageIntakeForm', 'intakeDecisions', 'goToCase'],
          intakeHistory: ['intakeOutcomeActivities', 'intakeWorkerHistory', 'contactOrganisation'],
        },
        ENTRIES: ['intakeOverview', 'manageIntake', 'intakeHistory'],
      },
    },
    APP_LAYOUT: {
      APP_BAR: {
        COMPONENTS: {
          main: ['publicActivitiesNotification', 'accountButton'],
          dropdown: ['accountPicName', 'divider', 'masquerade', 'normalChangePassword', 'signOut'],
        },
      },
      DRAWER: {
        COMPONENTS: {
          main: [
            'createNewIntake',
            'cases',
            'events',
            'supervision',
            'calendar',
            'weeklySessions',
            'reportsExpand',
            'reports',
            'adminExpand',
            'admin',
            'auditLogs',
          ],
          reports: [
            'centreCaseSummaryReport',
            'individualCounsellorReport',
            'counsellorWorkloadReport',
            'supervisionSessionReport',
            'intakeSpecialReport',
            'caseSpecialReport',
            'clientSpecialReport',
            'ccClientProfileReport',
            'sesvClientProfileReport',
            'caseMissingPrimaryProblemReport',
            'closedCaseReport',
            'dailyAppointmentReport',
            'ccSummaryReport',
            'seSummaryReport',
            'svSummaryReport',
            'individualCCCaseworkerReport',
            'individualSESVCaseworkerReport',
            'individualPathsCaseworkerReport',
            'individualCCICaseworkerReport',
            'individualProgrammesPerformanceReport',
          ],
          admin: ['clients', 'staffs', 'organisations', 'centres', 'supervision', 'displaySettings', 'emailSettings'],
          adminPermissions: ['clients', 'staffs'],
        },
      },
    },
  },
};

import { AttachmentPostData, CaseReviewAttachmentPostData } from 'types/attachments';
import { CaseReviewNewData, CaseReviewPostData } from 'types/cases';

export default {
  CASES: {
    BASE: 'cases',
    GET_CASE_REVIEW_LIST: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/case_reviews`,
    },
    GET_CASE_REVIEW: {
      DYNAMIC_ROUTE: (caseId: number, reviewId: number) => `${caseId}/case_reviews/${reviewId}`,
    },
    POST_CASE_REVIEW: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/case_reviews`,
      BODY: (data: CaseReviewNewData) => data,
    },
    PATCH_CASE_REVIEW: {
      DYNAMIC_ROUTE: (caseId: number, data: CaseReviewPostData) => `${caseId}/case_reviews/${data.case_review.id}`,
      BODY: (data: CaseReviewPostData) => data,
    },
    GET_CASE_REVIEW_LIST_BY_CASE: {
      DYNAMIC_ROUTE: (caseId: number) => `${caseId}/case_reviews`,
    },
    DELETE_REVIEW_SESSION: {
      DYNAMIC_ROUTE: (caseId: number, reviewId: number) => `${caseId}/case_reviews/${reviewId}`,
    },
    CASE_REVIEW_ATTACHMENT: {
      DYNAMIC_ROUTE: (caseId: number, reviewId: number) => `${caseId}/case_reviews/${reviewId}/attachments`,
      BODY: (attachmentData: CaseReviewAttachmentPostData | AttachmentPostData) => attachmentData,
    },
  },
};

import BaseAPI from './base';
import { CollectionQueryParams, CollectionData, ApiPromise, CollectionMeta } from 'types';
import { toQueryString } from 'utilities/url';
import {
  ClientData,
  CvwoPersonUnmaskedData,
  ClientNewData,
  ClientListData,
  CallerListData,
  StaffListData,
  CvwoOrganisationListData,
  CvwoVenueData,
  CvwoVenuePostData,
  CvwoVenueListData,
  SupervisionRelationshipPostData,
  StaffData,
  SupervisionRelationshipData,
  CaseTypeListData,
  CaseTypePostData,
  CaseTypeData,
  CaseCategoryListData,
  CaseCategoryPostData,
  CaseCategoryData,
  ClientPostData,
  CvwoOrganisationData,
  CvwoOrganisationPostData,
  StaffPostData,
  StaffNewData,
  CallerData,
  CallerPostData,
  CallerNewData,
  CentreListData,
  CentreData,
  CentreNewData,
  CentrePostData,
  DisplaySettingsData,
  EmailSetting,
  EmailSettingEntity,
  EmailSettingPostData,
  CanCarerListData,
  ClientHistoryPostData,
} from 'types/admin';
import { AttachmentData, AttachmentPostData, ClientAttachmentPostData } from 'types/attachments';
import { Result } from 'components/forms/fields/TextWithQuery';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.ADMIN;

export class AdminAPI extends BaseAPI {
  protected getAdminUrl() {
    return `${ROUTES.BASE}`;
  }

  protected getPeopleUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_PEOPLE_URL.ROUTE}`;
  }

  public getUnmaskedCvwoPerson(personId: number): ApiPromise<CvwoPersonUnmaskedData> {
    return this.get(
      `${this.getPeopleUrl()}/${ROUTES.GET_UNMASKED_PERSON_DATA.ROUTE}?${toQueryString({
        person_id: personId,
        full: true,
      })}`,
    );
  }

  protected getStaffUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_STAFF_URL.ROUTE}`;
  }

  protected getClientUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_CLIENT_URL.ROUTE}`;
  }

  protected getCallerUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_CALLER_URL.ROUTE}`;
  }

  protected getCanCarerUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_CAN_CARER_URL.ROUTE}`;
  }

  protected getVenueUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_VENUE_URL.ROUTE}`;
  }

  protected getCentreUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_CENTRE_URL.ROUTE}`;
  }

  protected getOrganisationUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_ORGANISATION_URL.ROUTE}`;
  }

  protected getSupervisionRelationshipUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_SUPERVISION_RELATIONSHIP_URL.ROUTE}`;
  }

  protected getCaseTypeUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_CASE_TYPE_URL.ROUTE}`;
  }

  protected getCaseCategoryUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_CASE_CATEGORY_URL.ROUTE}`;
  }

  protected getDisplaySettingsUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_DISPLAY_SETTINGS_URL.ROUTE}`;
  }

  protected getEmailSettingUrl() {
    return `${this.getAdminUrl()}/${ROUTES.GET_EMAIL_SETTING_URL.ROUTE}`;
  }

  public getStaffList(params: CollectionQueryParams): ApiPromise<CollectionData<StaffListData>> {
    return this.get(`${this.getStaffUrl()}?${toQueryString(params)}`);
  }

  public getStaffListPaginated(params: CollectionQueryParams): ApiPromise<CollectionData<StaffListData>> {
    return this.get(`${this.getStaffUrl()}/${ROUTES.GET_STAFF_LIST_PAGINATED.ROUTE}?${toQueryString(params)}`);
  }

  public getStaff(actableId: number): ApiPromise<{ staff: StaffData }> {
    return this.get(`${this.getStaffUrl()}/${ROUTES.GET_STAFF.DYNAMIC_ROUTE(actableId)}`);
  }

  public patchStaff(data: StaffPostData): ApiPromise<{ staff: StaffData }> {
    return this.patch(
      `${this.getStaffUrl()}/${ROUTES.PATCH_STAFF.DYNAMIC_ROUTE(data.staff.actable_id)}`,
      ROUTES.PATCH_STAFF.BODY(data),
    );
  }

  public postStaff(data: StaffNewData): ApiPromise<{ staff: StaffData }> {
    return this.post(`${this.getStaffUrl()}`, ROUTES.POST_STAFF.BODY(data));
  }

  public deleteStaff(staffId: number): ApiPromise<{}> {
    return this.delete(`${this.getStaffUrl()}/${ROUTES.DELETE_STAFF.DYNAMIC_ROUTE(staffId)}`);
  }

  public toggleHiddenStaff(actableId: number): ApiPromise<{ staff: StaffData }> {
    return this.patch(`${this.getStaffUrl()}/${ROUTES.TOGGLE_HIDDEN_STAFF.DYNAMIC_ROUTE(actableId)}`);
  }

  public getClientList(params: CollectionQueryParams): ApiPromise<CollectionData<ClientListData>> {
    return this.get(`${this.getClientUrl()}?${toQueryString(params)}`);
  }

  public getClient(actableId: number): ApiPromise<{ client: ClientData }> {
    return this.get(`${this.getClientUrl()}/${ROUTES.GET_CLIENT.DYNAMIC_ROUTE(actableId)}`);
  }

  public patchClient(data: ClientPostData): ApiPromise<{ client: ClientData }> {
    return this.patch(
      `${this.getClientUrl()}/${ROUTES.PATCH_CLIENT.DYNAMIC_ROUTE(data.client.actable_id)}`,
      ROUTES.PATCH_CLIENT.BODY(data),
    );
  }

  public postClient(data: ClientNewData): ApiPromise<{ client: ClientData }> {
    return this.post(`${this.getClientUrl()}`, ROUTES.POST_CLIENT.BODY(data));
  }

  public deleteClient(clientId: number): ApiPromise<{}> {
    return this.delete(`${this.getClientUrl()}/${ROUTES.DELETE_CLIENT.DYNAMIC_ROUTE(clientId)}`);
  }

  public getClientAttachmentList(
    params: CollectionQueryParams,
    actableId: number,
  ): ApiPromise<CollectionData<AttachmentData>, CollectionMeta> {
    return this.get(
      `${this.getClientUrl()}/${ROUTES.GET_CLIENT_ATTACHMENT_LIST.DYNAMIC_ROUTE(actableId)}?${toQueryString(params)}`,
    );
  }

  public addClientAttachmentList(
    actableId: number,
    attachmentData: ClientAttachmentPostData,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.post(
      `${this.getClientUrl()}/${ROUTES.ADD_CLIENT_ATTACHMENT_LIST.DYNAMIC_ROUTE(actableId)}`,
      ROUTES.ADD_CLIENT_ATTACHMENT_LIST.BODY(attachmentData),
    );
  }

  public editClientAttachment(
    actableId: number,
    attachmentData: AttachmentPostData,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.patch(
      `${this.getClientUrl()}/${ROUTES.EDIT_CLIENT_ATTACHMENT.DYNAMIC_ROUTE(actableId)}`,
      ROUTES.EDIT_CLIENT_ATTACHMENT.BODY(attachmentData),
    );
  }

  public getCanCarerListPaginated(params: CollectionQueryParams): ApiPromise<CollectionData<CanCarerListData>> {
    return this.get(`${this.getCanCarerUrl()}/${ROUTES.GET_CAN_CARER_LIST_PAGINATED.ROUTE}?${toQueryString(params)}`);
  }

  public postClientHistory(data: ClientHistoryPostData): ApiPromise<{ client: ClientData }> {
    return this.post(
      `${this.getClientUrl()}/${ROUTES.POST_CLIENT_HISTORY.DYNAMIC_ROUTE(data.client.actable_id)}`,
      ROUTES.POST_CLIENT_HISTORY.BODY(data),
    );
  }

  public getCaller(actableId: number): ApiPromise<CallerData> {
    return this.get(`${this.getCallerUrl()}/${ROUTES.GET_CALLER.DYNAMIC_ROUTE(actableId)}`);
  }

  public patchCaller(data: CallerPostData): ApiPromise<CallerData> {
    return this.patch(
      `${this.getCallerUrl()}/${ROUTES.PATCH_CALLER.DYNAMIC_ROUTE(data.caller.actable_id)}`,
      ROUTES.PATCH_CALLER.BODY(data),
    );
  }

  public postCaller(data: CallerNewData): ApiPromise<CallerData > {
    return this.post(`${this.getCallerUrl()}`, ROUTES.POST_CALLER.BODY(data));
  }

  // May remove CollectionQueryParams if not required
  public getVenueList(params: CollectionQueryParams): ApiPromise<CollectionData<CvwoVenueListData>, CollectionMeta> {
    return this.get(`${this.getVenueUrl()}?${toQueryString(params)}`);
  }

  public searchEventClientList(
    query: string,
    eventId: number,
    excludeClientInEvent: boolean,
  ): ApiPromise<ClientListData[], CollectionMeta> {
    const params = {
      event_id: eventId,
      exclude_client_in_event: excludeClientInEvent,
    };
    return this.get(
      `${this.getClientUrl()}/${ROUTES.SEARCH_EVENT_CLIENT_LIST.DYNAMIC_ROUTE(query)}?${toQueryString(params)}`,
    );
  }

  public searchEventStaffList(
    query: string,
    eventId: number,
    excludeStaffInEvent: boolean,
  ): ApiPromise<CollectionData<StaffListData>> {
    const params = {
      event_id: eventId,
      exclude_staff_in_event: excludeStaffInEvent,
    };
    return this.get(
      `${this.getStaffUrl()}/${ROUTES.SEARCH_EVENT_STAFF_LIST.DYNAMIC_ROUTE(query)}?${toQueryString(params)}`,
    );
  }

  public searchStaffList(
    query: string,
    params: { onlyCounsellor?: boolean; intakeRegionId?: number },
  ): ApiPromise<CollectionData<StaffListData>, CollectionMeta> {
    return this.get(`${this.getStaffUrl()}/${ROUTES.SEARCH_STAFF_LIST.DYNAMIC_ROUTE(query)}?${toQueryString(params)}`);
  }

  public searchClientList(query: string): ApiPromise<CollectionData<ClientListData>> {
    return this.get(`${this.getClientUrl()}/${ROUTES.SEARCH_CLIENT_LIST.DYNAMIC_ROUTE(query)}`);
  }

  public searchCallerList(query: string): ApiPromise<CollectionData<CallerListData>> {
    return this.get(`${this.getCallerUrl()}/${ROUTES.SEARCH_CALLER_LIST.DYNAMIC_ROUTE(query)}`);
  }

  public searchCanCarerList(query: string): ApiPromise<CollectionData<CanCarerListData>> {
    return this.get(`${this.getCanCarerUrl()}/${ROUTES.SEARCH_CAN_CARER_LIST.DYNAMIC_ROUTE(query)}`);
  }

  public findPeopleExactName(query: string): ApiPromise<Result> {
    return this.get(`${this.getPeopleUrl()}/${ROUTES.FIND_PEOPLE_EXACT_NAME.DYNAMIC_ROUTE(query)}`);
  }

  public findPeopleExactIC(query: string): ApiPromise<Result> {
    return this.get(`${this.getPeopleUrl()}/${ROUTES.FIND_PEOPLE_EXACT_IC.DYNAMIC_ROUTE(query)}`);
  }

  public findStaffExactUsername(query: string): ApiPromise<Result> {
    return this.get(`${this.getStaffUrl()}/${ROUTES.FIND_STAFF_EXACT_USERNAME.DYNAMIC_ROUTE(query)}`);
  }

  public findStaffExactEmail(query: string): ApiPromise<Result> {
    return this.get(`${this.getStaffUrl()}/${ROUTES.FIND_STAFF_EXACT_EMAIL.DYNAMIC_ROUTE(query)}`);
  }

  public searchOrganisationList(query: string): ApiPromise<CollectionData<CvwoOrganisationListData>> {
    return this.get(`${this.getOrganisationUrl()}/${ROUTES.SEARCH_ORGANISATION_LIST.DYNAMIC_ROUTE(query)}`);
  }

  public addCvwoVenue(venue: CvwoVenuePostData): ApiPromise<{ venue: CvwoVenueData }> {
    return this.post(`${this.getVenueUrl()}`, ROUTES.ADD_CVWO_VENUE.BODY(venue));
  }

  public getSupervisionRelationshipList(
    params: CollectionQueryParams,
  ): ApiPromise<CollectionData<SupervisionRelationshipData>> {
    return this.get(`${this.getSupervisionRelationshipUrl()}?${toQueryString(params)}`);
  }

  public addSupervisionRelationship(data: SupervisionRelationshipPostData): ApiPromise<SupervisionRelationshipData> {
    return this.post(`${this.getSupervisionRelationshipUrl()}`, ROUTES.ADD_SUPERVISION_RELATIONSHIP.BODY(data));
  }

  // This function is currently unused
  // It supports loadStaffSupervisionRelationships in operations
  public getSupervisionRelationshipByStaff(
    staffId: number,
    params: CollectionQueryParams,
  ): ApiPromise<CollectionData<SupervisionRelationshipData>> {
    return this.get(
      `${this.getStaffUrl()}/${ROUTES.GET_SUPERVISION_RELATIONSHIP_BY_STAFF.DYNAMIC_ROUTE(staffId)}?${toQueryString(
        params,
      )}`,
    );
  }

  public deleteSupervisionRelationship(id: number): ApiPromise<SupervisionRelationshipData> {
    return this.delete(
      `${this.getSupervisionRelationshipUrl()}/${ROUTES.DELETE_SUPERVISION_RELATIONSHIP.DYNAMIC_ROUTE(id)}`,
    );
  }

  public addCaseType(case_type: CaseTypePostData): ApiPromise<{ case_type: CaseTypeData }> {
    return this.post(`${this.getCaseTypeUrl()}`, ROUTES.ADD_CASE_TYPE.BODY(case_type));
  }

  public getCaseTypeList(params: CollectionQueryParams): ApiPromise<CollectionData<CaseTypeListData>, CollectionMeta> {
    return this.get(`${this.getCaseTypeUrl()}?${toQueryString(params)}`);
  }

  public patchCaseType(id: number, data: CaseTypePostData): ApiPromise<{ case_type: CaseTypeListData }> {
    return this.patch(
      `${this.getCaseTypeUrl()}/${ROUTES.PATCH_CASE_TYPE.DYNAMIC_ROUTE(id)}`,
      ROUTES.PATCH_CASE_TYPE.BODY(data),
    );
  }

  public deleteCaseType(id: number): ApiPromise<{}> {
    return this.delete(`${this.getCaseTypeUrl()}/${ROUTES.DELETE_CASE_TYPE.DYNAMIC_ROUTE(id)}`);
  }

  public addCaseCategory(case_category: CaseCategoryPostData): ApiPromise<{ case_category: CaseCategoryData }> {
    return this.post(`${this.getCaseCategoryUrl()}`, ROUTES.ADD_CASE_CATEGORY.BODY(case_category));
  }

  public getCaseCategoryList(
    params: CollectionQueryParams,
  ): ApiPromise<CollectionData<CaseCategoryListData>, CollectionMeta> {
    return this.get(`${this.getCaseCategoryUrl()}?${toQueryString(params)}`);
  }

  public patchCaseCategory(
    id: number,
    data: CaseCategoryPostData,
  ): ApiPromise<{ case_category: CaseCategoryListData }> {
    return this.patch(
      `${this.getCaseCategoryUrl()}/${ROUTES.PATCH_CASE_CATEGORY.DYNAMIC_ROUTE(id)}`,
      ROUTES.PATCH_CASE_CATEGORY.BODY(data),
    );
  }

  public deleteCaseCategory(id: number): ApiPromise<{}> {
    return this.delete(`${this.getCaseCategoryUrl()}/${ROUTES.DELETE_CASE_CATEGORY.DYNAMIC_ROUTE(id)}`);
  }

  public getCentreList(params: CollectionQueryParams): ApiPromise<CollectionData<CentreListData>> {
    return this.get(`${this.getCentreUrl()}?${toQueryString(params)}`);
  }

  public getCentreListPaginated(params: CollectionQueryParams): ApiPromise<CollectionData<CentreListData>> {
    return this.get(`${this.getCentreUrl()}/${ROUTES.GET_CENTRE_LIST_PAGINATED.ROUTE}?${toQueryString(params)}`);
  }

  public getCentre(centreId: number): ApiPromise<{ centre: CentreData }> {
    return this.get(`${this.getCentreUrl()}/${ROUTES.GET_CENTRE.DYNAMIC_ROUTE(centreId)}`);
  }

  public patchCentre(data: CentrePostData): ApiPromise<{ centre: CentreData }> {
    return this.patch(
      `${this.getCentreUrl()}/${ROUTES.PATCH_CENTRE.DYNAMIC_ROUTE(data.centre.id)}`,
      ROUTES.PATCH_CENTRE.BODY(data),
    );
  }

  public postCentre(data: CentreNewData): ApiPromise<{ centre: CentreData }> {
    return this.post(`${this.getCentreUrl()}`, ROUTES.POST_CENTRE.BODY(data));
  }

  // May remove CollectionQueryParams if not required
  public getOrganisationList(params: CollectionQueryParams): ApiPromise<CollectionData<CvwoOrganisationListData>> {
    return this.get(`${this.getOrganisationUrl()}?${toQueryString(params)}`);
  }

  public addCvwoOrganisation(org: CvwoOrganisationPostData): ApiPromise<{ organisation: CvwoOrganisationData }> {
    return this.post(`${this.getOrganisationUrl()}`, ROUTES.ADD_CVWO_ORGANISATION.BODY(org));
  }

  public patchCvwoOrganisation(
    id: number,
    org: CvwoOrganisationPostData,
  ): ApiPromise<{ organisation: CvwoOrganisationData }> {
    return this.patch(
      `${this.getOrganisationUrl()}/${ROUTES.PATCH_CVWO_ORGANISATION.DYNAMIC_ROUTE(id)}`,
      ROUTES.PATCH_CVWO_ORGANISATION.BODY(org),
    );
  }

  public getDisplaySettings(): ApiPromise<DisplaySettingsData> {
    return this.get(`${this.getDisplaySettingsUrl()}`);
  }

  public patchDisplaySettings(data: DisplaySettingsData): ApiPromise<DisplaySettingsData> {
    return this.patch(
      `${this.getDisplaySettingsUrl()}/${ROUTES.PATCH_DISPLAY_SETTINGS.DYNAMIC_ROUTE(data)}`,
      ROUTES.PATCH_DISPLAY_SETTINGS.BODY(data),
    );
  }

  public getEmailSetting(): ApiPromise<EmailSetting> {
    return this.get(`${this.getEmailSettingUrl()}`);
  }

  public updateEmailSetting(data: EmailSettingPostData): ApiPromise<EmailSettingEntity> {
    return this.patch(
      `${this.getEmailSettingUrl()}/${ROUTES.UPDATE_EMAIL_SETTING.DYNAMIC_ROUTE(data)}`,
      ROUTES.UPDATE_EMAIL_SETTING.BODY(data),
    );
  }
}

export default AdminAPI;

import BaseAPI from './base';
import { CollectionQueryParams, CollectionData, ApiPromise, CollectionMeta } from 'types';
import { toQueryString } from 'utilities/url';
import { TaskEditData, TaskListData } from 'types/tasks';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.TASKS;
export class TasksAPI extends BaseAPI {
  protected getTaskUrl() {
    return `${ROUTES.BASE}`;
  }

  public getTaskList(params: CollectionQueryParams): ApiPromise<CollectionData<TaskListData>, CollectionMeta> {
    return this.get(`${this.getTaskUrl()}?${toQueryString(params)}`);
  }
  
  public patchTask(taskData: TaskEditData): ApiPromise<CollectionData<TaskListData>, CollectionMeta> {
    return this.patch(`${this.getTaskUrl()}/`, ROUTES.UPDATE_TASKS_BODY([taskData]));
  }
}

export default TasksAPI;

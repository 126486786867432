import BaseAPI from './base';
import {
  EventListData,
  EventData,
  EventSessionListData,
  EventSessionData,
  EventPostData,
  EventNewData,
  EventSessionPostData,
  EventMassImportCheckResult,
  EventMassImportDataTypes,
  EventSessionNewData,
  EventMassImportListData,
  EventMassImportData,
  EventSessionAction,
  EventStatusAction,
  EventSummaryParams,
  EventSummaryData,
} from 'types/events';
import { CollectionQueryParams, CollectionData, ApiPromise, CollectionMeta } from 'types';
import { toQueryString } from 'utilities/url';
import { ClientListData, StaffListData } from 'types/admin';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.EVENTS;

export class EventsAPI extends BaseAPI {
  protected getEventUrl() {
    return `/${ROUTES.BASE}`;
  }

  public getEventList(params: CollectionQueryParams): ApiPromise<CollectionData<EventListData>, CollectionMeta> {
    return this.get(`${this.getEventUrl()}?${toQueryString(params)}`);
  }

  public getEvent(id: number): ApiPromise<{ event: EventData }> {
    return this.get(`${this.getEventUrl()}/${ROUTES.GET_EVENT.DYNAMIC_ROUTE(id)}`);
  }

  public patchEvent(data: EventPostData): ApiPromise<{ event: EventData }> {
    return this.patch(`${this.getEventUrl()}/${ROUTES.PATCH_EVENT.DYNAMIC_ROUTE(data)}`, ROUTES.PATCH_EVENT.BODY(data));
  }

  public patchEventState(
    eventId: number,
    action: EventStatusAction,
    data?: EventPostData,
  ): ApiPromise<{ event: EventData }> {
    return this.patch(
      `${this.getEventUrl()}/${ROUTES.PATCH_EVENT_STATE.DYNAMIC_ROUTE(eventId, action)}`,
      ROUTES.PATCH_EVENT_STATE.BODY(data),
    );
  }

  public postEvent(data: EventNewData): ApiPromise<{ event: EventData }> {
    return this.post(`${this.getEventUrl()}`, ROUTES.POST_EVENT.BODY(data));
  }

  public deleteEvent(eventId: number): ApiPromise<{}> {
    return this.delete(`${this.getEventUrl()}/${ROUTES.DELETE_EVENT.DYNAMIC_ROUTE(eventId)}`);
  }

  public getEventSessionList(
    params: CollectionQueryParams,
    eventId: number,
  ): ApiPromise<CollectionData<EventSessionListData>, CollectionMeta> {
    return this.get(
      `${this.getEventUrl()}/${ROUTES.GET_EVENT_SESSION_LIST.DYNAMIC_ROUTE(eventId)}?${toQueryString(params)}`,
    );
  }

  public getEventSession(eventId: number, sessionId: number): ApiPromise<EventSessionData> {
    return this.get(`${this.getEventUrl()}/${ROUTES.GET_EVENT_SESSION.DYNAMIC_ROUTE(eventId, sessionId)}`);
  }

  public patchEventSession(eventId: number, data: EventSessionPostData): ApiPromise<EventSessionData> {
    return this.patch(
      `${this.getEventUrl()}/${ROUTES.PATCH_EVENT_SESSION.DYNAMIC_ROUTE(eventId, data)}`,
      ROUTES.PATCH_EVENT_SESSION.BODY(data),
    );
  }

  public patchEventSessionState(
    eventId: number,
    sessionId: number,
    action: EventSessionAction,
    data?: EventSessionPostData,
  ): ApiPromise<EventSessionData> {
    return this.patch(
      `${this.getEventUrl()}/${ROUTES.PATCH_EVENT_SESSION_STATE.DYNAMIC_ROUTE(eventId, sessionId, action)}`,
      ROUTES.PATCH_EVENT_SESSION_STATE.BODY(data),
    );
  }

  public postEventSession(eventId: number, data: EventSessionNewData): ApiPromise<EventSessionData> {
    return this.post(
      `${this.getEventUrl()}/${ROUTES.POST_EVENT_SESSION.DYNAMIC_ROUTE(eventId)}`,
      ROUTES.POST_EVENT_SESSION.BODY(data),
    );
  }

  public deleteEventSession(eventId: number, sessionId: number): ApiPromise<{}> {
    return this.delete(`${this.getEventUrl()}/${ROUTES.DELETE_EVENT_SESSION.DYNAMIC_ROUTE(eventId, sessionId)}`);
  }

  public getEventClientList(params: CollectionQueryParams, id: number): ApiPromise<CollectionData<ClientListData>, CollectionMeta> {
    return this.get(`${this.getEventUrl()}/${ROUTES.GET_EVENT_CLIENT_LIST.DYNAMIC_ROUTE(id)}?${toQueryString(params)}`);
  }

  public addEventClient(eventId: number, clientId: number): ApiPromise<ClientListData> {
    return this.post(
      `${this.getEventUrl()}/${ROUTES.ADD_EVENT_CLIENT.DYNAMIC_ROUTE(eventId)}`,
      ROUTES.ADD_EVENT_CLIENT.BODY(clientId, eventId),
    );
  }

  public deleteEventClient(eventId: number, clientId: number): ApiPromise<{}> {
    return this.delete(
      `${this.getEventUrl()}/${ROUTES.DELETE_EVENT_CLIENT.DYNAMIC_ROUTE(eventId)}?client_id=${clientId}`,
    );
  }

  public getEventStaffList(params: CollectionQueryParams, id: number): ApiPromise<CollectionData<StaffListData>, CollectionMeta> {
    return this.get(`${this.getEventUrl()}/${ROUTES.GET_EVENT_STAFF_LIST.DYNAMIC_ROUTE(id)}?${toQueryString(params)}`);
  }

  public addEventStaff(eventId: number, staffId: number): ApiPromise<StaffListData> {
    return this.post(
      `${this.getEventUrl()}/${ROUTES.ADD_EVENT_STAFF.DYNAMIC_ROUTE(eventId)}`,
      ROUTES.ADD_EVENT_STAFF.BODY(staffId, eventId),
    );
  }

  public deleteEventStaff(eventId: number, staffId: number): ApiPromise<{}> {
    return this.delete(`${this.getEventUrl()}/${ROUTES.DELETE_EVENT_STAFF.DYNAMIC_ROUTE(eventId)}?staff_id=${staffId}`);
  }

  public getEventSummary(eventId: number, params: EventSummaryParams): ApiPromise<EventSummaryData> {
    return this.get(
      `${this.getEventUrl()}/${ROUTES.GET_EVENT_SUMMARY.DYNAMIC_ROUTE(eventId)}?${toQueryString(params)}`,
    );
  }

  public getEventMassImportList(
    params: CollectionQueryParams,
    eventId: number,
  ): ApiPromise<CollectionData<EventMassImportListData>> {
    return this.get(
      `${this.getEventUrl()}/${ROUTES.GET_EVENT_MASS_IMPORT_LIST.DYNAMIC_ROUTE(eventId)}?${toQueryString(params)}`,
    );
  }

  public getEventMassImport(eventId: number, massImportId: number): ApiPromise<EventMassImportData> {
    return this.get(`${this.getEventUrl()}/${ROUTES.GET_EVENT_MASS_IMPORT.DYNAMIC_ROUTE(eventId, massImportId)}`);
  }

  public massImportCheck(
    eventId: number,
    data: Array<Array<EventMassImportDataTypes>>,
  ): ApiPromise<EventMassImportCheckResult> {
    return this.post(
      `${this.getEventUrl()}/${ROUTES.MASS_IMPORT_CHECK.DYNAMIC_ROUTE(eventId)}`,
      ROUTES.MASS_IMPORT_CHECK.BODY(data),
    );
  }

  public massImportCreate(eventId: number, data: EventMassImportCheckResult): ApiPromise<EventMassImportData> {
    return this.post(
      `${this.getEventUrl()}/${ROUTES.MASS_IMPORT_CREATE.DYNAMIC_ROUTE(eventId)}`,
      ROUTES.MASS_IMPORT_CREATE.BODY(data),
    );
  }
}

export default EventsAPI;

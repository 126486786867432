import BaseAPI from './base';
import { FileUploadResponse } from 'types/attachments';
import { ApiPromise } from 'types';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.ATTACHMENTS;
const INTAKE_ROUTES = apiRoutes.INTAKES;
const CASE_ROUTES = apiRoutes.CASES;
const ADMIN_ROUTES = apiRoutes.ADMIN;

export class AttachmentsAPI extends BaseAPI {
  protected getAttachmentUrl() {
    return `/${ROUTES.BASE}`;
  }

  protected getIntakeAttachmentUrl() {
    return `/${INTAKE_ROUTES.BASE}`;
  }

  protected getCaseAttachmentUrl() {
    return `/${CASE_ROUTES.BASE}`;
  }

  protected getClientAttachmentUrl() {
    return `${ADMIN_ROUTES.BASE}/${ADMIN_ROUTES.GET_CLIENT_URL.ROUTE}`;
  }

  public createAttachment(attachment: File): ApiPromise<FileUploadResponse> {
    const formData = new FormData();
    formData.append('file', attachment, attachment.name);
    return this.post(`${this.getAttachmentUrl()}`, ROUTES.CREATE_ATTACHMENT.BODY(formData));
  }

  public deleteAttachment(id: number): ApiPromise<{}> {
    return this.delete(`${this.getAttachmentUrl()}/${ROUTES.DELETE_ATTACHMENT.DYNAMIC_ROUTE(id)}`);
  }

  public deleteIntakeAttachment(intakeID: number, fileUploadID: number): ApiPromise<{}> {
    return this.delete(
      `${this.getIntakeAttachmentUrl()}/${INTAKE_ROUTES.DELETE_ATTACHMENT.DYNAMIC_ROUTE(intakeID, fileUploadID)}`,
    );
  }

  public deleteCaseAttachment(caseID: number, fileUploadID: number): ApiPromise<{}> {
    return this.delete(
      `${this.getCaseAttachmentUrl()}/${CASE_ROUTES.DELETE_ATTACHMENT.DYNAMIC_ROUTE(caseID, fileUploadID)}`,
    );
  }

  public deleteCaseSessionAttachment(caseSessionID: number, fileUploadID: number): ApiPromise<{}> {
    return this.delete(
      `${this.getCaseAttachmentUrl()}/${CASE_ROUTES.DELETE_CASE_SESSION_ATTACHMENT.DYNAMIC_ROUTE(
        caseSessionID,
        fileUploadID,
      )}`,
    );
  }

  public deleteCaseReviewAttachment(caseReviewID: number, fileUploadID: number): ApiPromise<{}> {
    return this.delete(
      `${this.getCaseAttachmentUrl()}/${CASE_ROUTES.DELETE_CASE_REVIEW_ATTACHMENT.DYNAMIC_ROUTE(
        caseReviewID,
        fileUploadID,
      )}`,
    );
  }

  public deleteCaseRequestAttachment(caseRequestID: number, fileUploadID: number): ApiPromise<{}> {
    return this.delete(
      `${this.getCaseAttachmentUrl()}/${CASE_ROUTES.DELETE_CASE_REQUEST_ATTACHMENT.DYNAMIC_ROUTE(
        caseRequestID,
        fileUploadID,
      )}`,
    );
  }

  public deleteClientAttachment(clientID: number, fileUploadID: number): ApiPromise<{}> {
    return this.delete(
      `${this.getClientAttachmentUrl()}/${ADMIN_ROUTES.DELETE_CLIENT_ATTACHMENT.DYNAMIC_ROUTE(clientID, fileUploadID)}`,
    );
  }
}

export default AttachmentsAPI;

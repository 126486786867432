export default {
  TABLE: {
    ATTACHMENT: {
      COLUMN: ['date', 'subtype', 'status', 'fileName', 'cvwoCreatedBy', 'remarks', 'actions'],
    },
    CASE: {
      INITIAL_SORT_KEY: 'case_cases.start_date',
      INITIAL_SORT_ORDER: 'desc',
      COLUMN: [
        'caseNo',
        'contacts',
        'mainCounsellor',
        'dateOfNextReview',
        'dateOpened',
        'dateClosed',
        'caseType',
        'daysSinceLastSession',
        'actions',
      ],
      FILTERS: ['centres', 'counsellors', 'caseTypes', 'caseStates', 'caseRiskStatus'],
      TEMPORAL_PERMISSIONS: {
        TITLE: 'Case Workers',
        DELETE_MESSAGE_LABEL: 'Case Worker',
        ADD_LABEL: 'Case Worker',
        COLUMN: ['name', 'startDate', 'endDate', 'actions'],
        DATA: ['permissionStaffName', 'startDate', 'endDate', 'actions'],
      },
    },
    CASE_ACTIVITY: {
      COLUMN: ['date', 'name', 'title', 'status', 'duration', 'createdBy', 'vettedAt', 'vettedBy', 'actions'],
      SUPERVISION_SESSION_LABEL: '',
    },
    CASE_REVIEW: {
      COLUMN: ['date', 'attendees', 'nextReviewDate', 'createdBy', 'actions'],
    },
    CLIENT: {
      COLUMN: ['name', 'ic', 'contact', 'nextOfKins', 'actions'],
    },
    STAFF: {
      COLUMN: ['name', 'roles', 'title', 'status'],
    },
    TASK: {
      COLUMN: ['item', 'status', 'date', 'actions'],
    },
    NEXT_OF_KIN: {
      COLUMN: ['primary', 'involved', 'householdMember', 'relationship', 'customName'],
    },
    REFERRALS: {
      TITLE: 'Referral Sources',
      COLUMN: [
        'referralDate',
        'type',
        'personSortable',
        'organisationSortable',
        'contacts',
        'address',
        'relationship',
        'remarksSortable',
      ],
    },
    INTAKE: {
      INITIAL_SORT_KEY: 'cms_intake_intakes.start_date',
      INITIAL_SORT_ORDER: 'desc',
      COLUMN: [
        'intakeRef',
        'personName',
        'personIdentity',
        'assignedCounsellor',
        'latestReferralDate',
        'intakeStatus',
        'actions',
      ],
      DATA: ['intakeRef', 'clientName', 'identityDocument', 'staffName', 'intakeDate', 'intakeStatus', 'showButton'],
      FILTERS: ['centres', 'assignedCounsellor', 'singleSelectIntakeStatus'],
    },
    REPORTS: {
      CLIENT_SPECIAL_REPORT: {
        CLIENTS_TABLE_COLUMN: [
          'clientName',
          'caseNumber',
          'caseReference',
          'counsellor',
          'identityDocumentation',
          'riskStatus',
          'age',
          'maritalStatus',
          'gender',
          'individualIncome',
          'occupationStatus',
          'race',
          'religion',
          'educationLevel',
          'languages',
          'lastModifed',
          'modifiedBy',
          'actions',
        ],
      },
    },
    OUTCOME_ACTIVITIES: {
      COLUMN: ['date', 'finalOutcome', 'finalOutcomeRemarks', 'decision', 'decisionRemarks'],
    },
  },
};

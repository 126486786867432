export default {
  FORMS: {
    ADMIN: {
      CLIENT: {
        NEW: {
          IS_GENDER_REQUIRED: true,
          IS_RISK_LEVEL_REQUIRED: false,
          IS_IDENTITY_DOCUMENT_REQUIRED: false,
          IS_INTAKE_CLIENT_REQUIRED: false,
          COMPONENTS: {
            personalParticulars: [
              'name',
              'traditionalName',
              'identityDocumentType',
              'identityDocument',
              'salutation',
              'dateOfBirth',
              'nationality',
              'placeOfBirth',
              'gender',
              'race',
              'religion',
              'ethnicity',
              'householdPattern',
              'atRisk',
              'workingAuthorityStartDate',
              'workingAuthorityEndDate',
              'centre',
            ],
            languages: ['peopleLanguagesAttributes'],
            contactDetails: ['contactsAttributes'],
            addresses: ['peopleAddressesAttributes'],
            emergencyContact: ['clientsEmergencyContactsAttributes'],
            backgroundInformation: [
              'organisationId',
              'occupation',
              'occupationStatus',
              'highestEducationLevel',
              'individualIncome',
              'familyIncome',
              'backgroundInformationRemarks',
            ],
            specialRequests: ['clientsSpecialNeedsAttributes'],
            nextOfKin: ['peopleNextOfKinAttributes'],
            maritalHistory: ['peopleMaritalHistoriesAttributes'],
            counsellingHistory: ['clientsCounsellingHistoryAttributes'],
          },
          ENTRIES: [
            'personalParticulars',
            'languages',
            'contactDetails',
            'addresses',
            'emergencyContact',
            'backgroundInformation',
            'specialRequests',
            'nextOfKin',
            'maritalHistory',
            'counsellingHistory',
          ],
        },
      },
    },
    CASE: {
      DETAILS: {
        COMPONENTS: {
          overviewSection: [
            'overviewCaseRefNo',
            'overviewCaseNo',
            'overviewCaseType',
            'overviewDateOpened',
            'overviewCentre',
            'overviewIntakeDate',
            'overviewCounsellingFee',
            'overviewCaseRemarks',
          ],
          caseClientsSection: ['caseClientsAttributes'],
        },
        ENTRIES: ['overviewSection', 'caseClientsSection'],
      },
      EDIT_SESSION: {
        IS_LOCATION_REQUIRED: false,
        IS_CLIENT_REQUIRED: false,
        IS_CASE_WORKER_REQUIRED: false,
        IS_TITLE_REQUIRED: true,
        COMPONENTS: {
          sessionSubtypesSection: ['sessionSubtypes'],
        },
        ENTRIES: ['title', 'sessionDateTime', 'sessionType', 'sessionSubtypesSection', 'attendees'],
      },
      NEW_SESSION: {
        IS_LOCATION_REQUIRED: false,
        IS_CLIENT_REQUIRED: false,
        IS_CASE_WORKER_REQUIRED: false,
        IS_TITLE_REQUIRED: true,
        COMPONENTS: {
          sessionSubtypesSection: ['sessionSubtypes'],
        },
        ENTRIES: ['title', 'sessionDateTime', 'sessionType', 'sessionSubtypesSection', 'attendees'],
      },
      NEW_REVIEW: {
        COMPONENTS: {},
        ENTRIES: ['eventDate', 'duration', 'summaryNotes', 'nextReview', 'typeOfReview', 'attendees'],
      },
      CASE_TRANSFER: {
        ENTRIES: ['transfer_reason'],
      },
      NEW_TEMPORAL_PERMISSION: {
        COMPONENTS: {},
        ENTRIES: ['staffAutocomplete2', 'startEndDate'],
      },
      EDIT_TEMPORAL_PERMISSION: {
        COMPONENTS: {},
        ENTRIES: ['startEndDate'],
      },
    },
    INTAKE: {
      DETAILS: {
        REFERRAL: {
          COMPONENTS: {},
          ENTRIES: ['referralMode', 'referralType', 'caller', 'referralDate', 'referringOrganisation2', 'remarks'],
        },
        INFO: {
          COMPONENTS: {
            intakeInfo: ['intakeOfficer', 'intakeDate', 'counsellingFee', 'caseType', 'intakeRemarks'],
          },
          ENTRIES: ['intakeInfo', 'sourceOfCase', 'basicInfo'],
        },
      },
      DETAILS_OVERVIEW: {
        PROMOTE_CASE: {
          COMPONENTS: {},
          ENTRIES: ['promoteToCase', 'ecmsId', 'caseType', 'remarks'],
        },
      },
      NEW_INTAKE: {
        COMPONENTS: {
          intakeInformationSection: [
            'intakeOfficer',
            'intakeDate',
            'counsellingFee',
            'caseType',
            'centre',
            'intakeRemarks',
          ],
          sourceOfCaseSection: ['referralSourcesAttrib'],
          clientInfoSection: ['clientAttrib'],
        },
        ENTRIES: ['intakeInformationSection', 'sourceOfCaseSection', 'clientInfoSection'],
      },
    },
  },
};

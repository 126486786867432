import { mergeDeep } from './merge_helper';
import { Functionality } from './constants/Functionality';
import { ConstantsEnum, ConstantsDisplay, ConstantsBackbone } from './constants/ConstantsEnumDisp';
import { Label } from './constants/Label';
import { Group } from './constants/Group';
import { Forms } from './constants/Forms';
import { Table } from './constants/Table';
import { Views } from './constants/Views';
import { Color } from './constants/Color';

import defaultConstants from 'constants/default.json';
import ccccConstants from 'constants/cccc.json';
import ccpConstants from 'constants/ccp.json';
import feiyueConstants from 'constants/feiyue.json';
import lionsConstants from 'constants/lions.json';
import mwcConstants from 'constants/mwc.json';
import sageConstants from 'constants/sage.json';
import casConstants from 'constants/cas.json';

import defaultApiRoutes from 'constants/api_routes/default';
import ccpApiRoutes from 'constants/api_routes/ccp';
import ccccApiRoutes from 'constants/api_routes/cccc';
import feiyueApiRoutes from 'constants/api_routes/feiyue';
import lionsApiRoutes from 'constants/api_routes/lions';
import mwcApiRoutes from 'constants/api_routes/mwc';
import sageApiRoutes from 'constants/api_routes/sage';
import casApiRoutes from 'constants/api_routes/cas';

import defaultFrontendRoutes from 'constants/frontend_routes/default';
import ccccFrontendRoutes from 'constants/frontend_routes/cccc';
import ccpFrontendRoutes from 'constants/frontend_routes/ccp';
import feiyueFrontendRoutes from 'constants/frontend_routes/feiyue';
import lionsFrontendRoutes from 'constants/frontend_routes/lions';
import mwcFrontendRoutes from 'constants/frontend_routes/mwc';
import sageFrontendRoutes from 'constants/frontend_routes/sage';
import casFrontendRoutes from 'constants/frontend_routes/cas';

import defaultTableConstants from 'constants/tables/default';
import ccccTableConstants from 'constants/tables/cccc';
import ccpTableConstants from 'constants/tables/ccp';
import feiyueTableConstants from 'constants/tables/feiyue';
import lionsTableConstants from 'constants/tables/lions';
import mwcTableConstants from 'constants/tables/mwc';
import sageTableConstants from 'constants/tables/sage';
import casTableConstants from 'constants/tables/cas';

import defaultFormsConstants from 'constants/forms/default';
import ccccFormsConstants from 'constants/forms/cccc';
import ccpFormsConstants from 'constants/forms/ccp';
import feiyueFormsConstants from 'constants/forms/feiyue';
import lionsFormsConstants from 'constants/forms/lions';
import mwcFormsConstants from 'constants/forms/mwc';
import sageFormsConstants from 'constants/forms/sage';
import casFormsConstants from 'constants/forms/cas';

import defaultViewsConstants from 'constants/views/default';
import ccccViewsConstants from 'constants/views/cccc';
import ccpViewsConstants from 'constants/views/ccp';
import feiyueViewsConstants from 'constants/views/feiyue';
import lionsViewsConstants from 'constants/views/lions';
import mwcViewsConstants from 'constants/views/mwc';
import sageViewsConstants from 'constants/views/sage';
import casViewsConstants from 'constants/views/cas';

export interface RailsConstants {
  LABEL: Label;

  ENUM: ConstantsEnum<ConstantsBackbone>;

  DISPLAY: ConstantsDisplay<ConstantsBackbone>;

  TABLE: Table;

  FORMS: Forms;

  VIEWS: Views;

  GROUP: Group;

  FUNCTIONALITY: Functionality;

  COLOR: Color;

  FRONTEND_ROUTE: string[];
}

const formatEnum = <P>(obj: P) => {
  const keys = Object.keys(obj) as (keyof P)[];
  const newObj: any = {};
  keys.map((key: keyof P) => {
    if (typeof obj[key] == 'string') {
      newObj[key] = obj[key];
      newObj[obj[key]] = parseInt(key as string);
    } else {
      newObj[key] = formatEnum(obj[key]);
    }
  });
  return newObj as P;
};

export let constants: RailsConstants = {
  ...(defaultConstants.CONSTANTS as any),
  ...(defaultFrontendRoutes as any),
  ...(defaultTableConstants as any),
  ...(defaultFormsConstants as any),
  ...(defaultViewsConstants as any),
  ENUM: formatEnum(defaultConstants.CONSTANTS.ENUM),
};

export let apiRoutes = defaultApiRoutes;

export const SET_CENTRE = (parsedConstants: RailsConstants) => {
  const CURRENT_CENTRE_NAME = process.env.REACT_APP_CENTRE_NAME;
  switch (CURRENT_CENTRE_NAME) {
    case 'CCCC': {
      constants = Object.freeze(
        mergeDeep(
          constants,
          ccccConstants.CONSTANTS,
          ccccFrontendRoutes,
          ccccTableConstants,
          ccccFormsConstants,
          ccccViewsConstants,
          {
            ...parsedConstants,
            ENUM: formatEnum(parsedConstants.ENUM),
          },
        ),
      );
      apiRoutes = Object.freeze(
        mergeDeep(defaultApiRoutes, {
          ...(ccccApiRoutes as any),
        }),
      );
      break;
    }
    case 'CCP': {
      constants = Object.freeze(
        mergeDeep(
          constants,
          ccpConstants.CONSTANTS,
          ccpFrontendRoutes,
          ccpTableConstants,
          ccpFormsConstants,
          ccpViewsConstants,
          {
            ...parsedConstants,
            ENUM: formatEnum(parsedConstants.ENUM),
          },
        ),
      );
      apiRoutes = Object.freeze(
        mergeDeep(defaultApiRoutes, {
          ...(ccpApiRoutes as any),
        }),
      );
      break;
    }
    case 'FEIYUE': {
      constants = Object.freeze(
        mergeDeep(
          constants,
          feiyueConstants.CONSTANTS,
          feiyueFrontendRoutes,
          feiyueTableConstants,
          feiyueFormsConstants,
          feiyueViewsConstants,
          {
            ...parsedConstants,
            ENUM: formatEnum(parsedConstants.ENUM),
          },
        ),
      );
      apiRoutes = Object.freeze(
        mergeDeep(defaultApiRoutes, {
          ...(feiyueApiRoutes as any),
        }),
      );
      break;
    }
    case 'LIONS': {
      constants = Object.freeze(
        mergeDeep(
          constants,
          lionsConstants.CONSTANTS,
          lionsFrontendRoutes,
          lionsTableConstants,
          lionsFormsConstants,
          lionsViewsConstants,
          {
            ...parsedConstants,
            ENUM: formatEnum(parsedConstants.ENUM),
          },
        ),
      );
      apiRoutes = Object.freeze(
        mergeDeep(defaultApiRoutes, {
          ...(lionsApiRoutes as any),
        }),
      );
      break;
    }
    case 'MWC': {
      constants = Object.freeze(
        mergeDeep(
          constants,
          mwcConstants.CONSTANTS,
          mwcFrontendRoutes,
          mwcTableConstants,
          mwcFormsConstants,
          mwcViewsConstants,
          {
            ...parsedConstants,
            ENUM: formatEnum(parsedConstants.ENUM),
          },
        ),
      );
      apiRoutes = Object.freeze(
        mergeDeep(defaultApiRoutes, {
          ...(mwcApiRoutes as any),
        }),
      );
      break;
    }
    case 'SAGE': {
      constants = Object.freeze(
        mergeDeep(
          constants,
          sageConstants.CONSTANTS,
          sageFrontendRoutes,
          sageTableConstants,
          sageFormsConstants,
          sageViewsConstants,
          {
            ...parsedConstants,
            ENUM: formatEnum(parsedConstants.ENUM),
          },
        ),
      );
      apiRoutes = Object.freeze(
        mergeDeep(defaultApiRoutes, {
          ...(sageApiRoutes as any),
        }),
      );
      break;
    }
    case 'CAS': {
      constants = Object.freeze(
        mergeDeep(
          constants,
          casConstants.CONSTANTS,
          casFrontendRoutes,
          casTableConstants,
          casFormsConstants,
          casViewsConstants,
          {
            ...parsedConstants,
            ENUM: formatEnum(parsedConstants.ENUM),
          },
        ),
      );
      apiRoutes = Object.freeze(
        mergeDeep(defaultApiRoutes, {
          ...(casApiRoutes as any),
        }),
      );
      break;
    }
    default: {
      break;
    }
  }
  if (process.env.NODE_ENV == 'development') {
    console.info('Loaded constants: ', JSON.stringify(constants));
  }
};
